.internship-p{
    margin: 15px  0;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.internship  h2{
    text-align: left;
}
.internship ul{
    margin-left: 36px;
    margin-bottom: 10px;
}
@media (max-width:700px) {
    .internship{
        padding: 20px !important;
    }
}