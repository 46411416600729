@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import "./Components/Internship/Internship.css";
@import "./Components/Testimonials/Testimonials.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
body {
  background: #fff;
  /* background: #f5f5f5; */
}
a {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}
.container {
  padding-left: 5%;
  padding-right: 5%;
}
.children {
  margin-top: 65px;
}

@media (max-width: 885px) {
  .container {
    padding-left: 2%;
    padding-right: 2%;
  }
}
