nav {
  width: 100%;
  background: #0c1f26;
  color: #fff;
  padding: 4px 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  height: 65px;
}
.nav-fix {
  background: #0c1f26;
}
nav ul li {
  display: inline-block;
  list-style: none;
  margin: 5px 20px;
  font-size: 16px;
}
.btn {
  background: #124e66;
  color: #ffffff;
  padding: 14px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
}

.logo {
  width: 200px;
}
.menu-icon {
  display: none;
}
/* ----------------- Media Query----------------- */

@media (max-width: 885px) {
  .logo {
    width: 150px;
  }
  .btn {
    padding: 10px 18px;
  }
  nav ul li {
    margin: 3px 7px;
  }
}

@media (max-width: 626px) {
  nav {
    padding: 15px 0;
  }
  nav ul {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0c1f26;
    z-index: -1;
    width: 170px;
    padding-top: 70px;
    transition: 0.5s;
  }
  nav ul li {
    display: block;
    margin: 20px 20px;
  }
  .menu-icon {
    display: block;
    width: 30px;
    cursor: pointer;
    margin-right: 10px;

  }
  .hide-moobile-menu{
    right: -170px;
  }
}
